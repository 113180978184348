<template>
  <div>
    <!-- modal -->
    <b-modal v-model="dialog" size="lg" title="แก้ไขหัสพิน" ok-only ok-title="ปิด">

      <b-row class="invoice-add">
        <b-col cols="12" xl="12" md="12">
          <b-form enctype="multipart/form-data" @submit.prevent>


            <div ref="form" class="repeater-form mr-4 ml-4">

              <b-form @submit.prevent class="mr-4 ml-4">
                <b-row>
                  <b-col cols="12">
                    <b-form-group label="รหัสพิน">
                      <b-input-group class="input-group-merge">
                        <b-form-input id="pin_data" v-model="pin_data" type="text" placeholder="9999"  />
                      </b-input-group>
                      <b-alert v-if="check.pin_data == true" variant="danger" show class="mr-2 ml-2 mt-1">
                        <span>Please Enter</span>
                      </b-alert>
                    </b-form-group>
                  </b-col>


                </b-row>
                <div align="center" class="m-2">
                  <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit" variant="primary" class="mr-1"
                    @click="update()">
                    บันทึก
                  </b-button>
                </div>
              </b-form>

            </div>


          </b-form>
        </b-col>
      </b-row>

    </b-modal>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BButton,
  BCardText,
  BListGroupItem,
  BCardTitle,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BFormTextarea,
  BCardCode,
  BFormFile,
  BImg,
  BMedia,
  VBModal,
  BFormDatepicker,
  BCarousel,
  BCarouselSlide,
  BContainer,
  BFormText,
  BAlert,
  BFormSelect,
} from "bootstrap-vue";
import Swal from "sweetalert2";
import Ripple from "vue-ripple-directive";
import "animate.css";
import api from "@/api";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
export default {
  components: {
    flatPickr,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BCardText,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormTextarea,
    BCardCode,
    BFormFile,
    BListGroupItem,
    BCardTitle,
    BImg,
    BMedia,
    VBModal,
    BFormDatepicker,
    BCarousel,
    BCarouselSlide,
    BContainer,
    BFormText,
    BAlert,
    quillEditor,
    vSelect,
    BFormSelect,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      dialog: null,
      Eid: null,
      pin_data: null,
      check: {
        pin_data: false,

      },
    };
  },
  methods: {
    update() {
      console.log("update");

      if (this.pin_data && this.pin_data.length == 4) {
        Swal.fire({
          title: "ยืนยันการบันทึกข้อมูล",
          text: "",
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "ยืนยัน",
          cancelButtonText: "ยกเลิก",
          showLoaderOnConfirm: true,
          preConfirm: () => { },
        }).then((result) => {
          let formData = new FormData();
          formData.append("id", this.Eid);
          formData.append("pin", this.pin_data);

          api.post("/cnemauser_update_pin", formData).then((response) => {
            console.log("response", response.data);
            this.pin_data = null
            this.Eid = null
            if (response) {
              Swal.fire({
                icon: "success",
                title: "success",
                showConfirmButton: false,
                timer: 1500,
              });
              this.dialog = false;
              this.refresh();
            }
          });
        });
      } else {

        this.check.pin_data = true;
        console.log("pin_data null");


      }

    },

    async showModaledit(data) {

      console.log("data", data);
      this.Eid = data.id;
      this.pin_data = data.pin

      this.dialog = true;
    },

    refresh() {
      this.$emit("loaddata", "loaddata");
    },

  },
};
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>